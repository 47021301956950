
































































































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Product, Subscription } from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";
import { EUVat, EUVatRate } from "@/core/plugins/eu-vat-rates";
import { paymentSettings } from "@/settings";

@Component({
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
  },
})
export default class ActiveSubscription extends Vue {
  @Getter("products/retrieved") retrieved!: boolean;
  @Getter("products/products") products!: Product[];
  @Getter("profile/subscriptionDetails") subscriptionDetails!: Subscription;
  @Action("profile/cancelSubscription") cancelSubscription!: Function;
  @Action("products/get") getProducts!: Function;

  // TODO: Get country and tax exempt from customer
  country = "DE";
  euVat: EUVatRate = EUVat.rates.DE;
  buyAsCompany = false;
  vatValidated = false;
  reverseCharge = false;
  cancelSubscriptionDialog = false;

  get isYearly(): boolean {
    return this.subscriptionDetails.planInterval === "year";
  }

  get currentProduct() {
    // Get product by price id (tier)
    return this.products.filter(product => {
      return (
        product.monthlyPrice === this.subscriptionDetails.priceId ||
        product.yearlyPrice === this.subscriptionDetails.priceId
      );
    })[0];
  }

  get vatRate() {
    console.debug(this.buyAsCompany, this.euVat, this.vatValidated);
    if (this.buyAsCompany && this.euVat && this.vatValidated) {
      if (this.euVat.country === "Germany") {
        console.log("No Reverse Charge in DE");
        this.reverseCharge = false;
        return this.euVat.standard_rate as number;
      }
      if (!this.euVat.country) {
        console.log("No Reverse Charge (outside EU) in", this.country);
        this.reverseCharge = false;
        return 0;
      }
      console.log("Apply Reverse Charge in", this.country);
      this.reverseCharge = true;
      return this.euVat.standard_rate as number;
    }
    console.log("No valid Company - Apply default tax in", this.country);
    this.reverseCharge = false;
    return (this.euVat.standard_rate as number) || 0;
  }

  // TODO: Replace reverseCharge checks with taxExempt === "reverse"
  get taxExempt() {
    if (this.buyAsCompany && this.euVat && this.vatValidated) {
      if (this.euVat.country === "Germany")
        //console.log("No Reverse Charge in DE");
        return "none";

      if (!this.euVat.country)
        //console.log("No Reverse Charge (outside EU) in", this.country);
        return "exempt";

      //console.log("Apply Reverse Charge in", this.country);
      return "reverse";
    }
    if (this.euVat.country)
      //console.log("Normal Vat in", this.country);
      return "none";

    //console.log("No Vat (outside EU) in", this.country);
    return "exempt";
  }

  get grossAmount() {
    if (this.retrieved)
      return !this.isYearly
        ? this.currentProduct.unitAmountMonthly
        : this.currentProduct.unitAmountYearly;
    else return 0;
  }

  get netOriginal() {
    if (paymentSettings.vatIncluded)
      return this.grossAmount / (1 + this.vatRate / 100);

    return this.grossAmount;
  }

  get grossOriginal() {
    if (paymentSettings.vatIncluded) return this.grossAmount;

    return this.grossAmount + (this.grossAmount / 100) * this.vatRate;
  }

  formatPrice(amount: number) {
    return `€${amount.toFixed(2)}`;
  }

  formatAmount(amount: number) {
    // Use on all calculations with currency amounts per value to prevent 3rd decimal (.xx5) rounding issues of percent/tax calculations
    return parseFloat((amount / 100).toFixed(2));
  }

  addPrefix = (string: string, prefix: string) => {
    return prefix + string;
  };

  // TODO: Map all subscriptionDetails.status options to i18n

  get canceledSubscription() {
    const now = new Date();
    return !(
      !this.subscriptionDetails.cancelAtPeriodEnd ||
      (this.subscriptionDetails.cancelAtPeriodEnd &&
        this.subscriptionDetails.cancelAt &&
        this.subscriptionDetails.cancelAt > now)
    );
  }

  get paymentMethod() {
    if (this.subscriptionDetails.paymentMethod) {
      console.log("paymentMethod!", this.subscriptionDetails);
      let type = "card";

      switch (type) {
        case "card":
          return {
            type: "Credit Card",
            brand: this.subscriptionDetails.paymentMethod.card.brand.toLocaleUpperCase(
              "DE",
            ),
            icon: "mdi-credit-card",
          };
        // TODO: Just a sample, correctly add all used payment methods
        case "sofort":
          return {
            type: "Sofortüberweisung",
            brand: "Klarna",
            icon: "mdi-bank",
          };
        default:
          return { type: "", brand: "", icon: "" };
      }
    }
    return { type: "", brand: "", icon: "" };
  }

  created() {
    if (!this.retrieved) this.getProducts();
  }
}
